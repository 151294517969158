// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-article-a-love-letter-to-my-favorite-plants-as-told-through-illustrations-ttdf-tsx": () => import("./../../../src/pages/article/a-love-letter-to-my-favorite-plants-as-told-through-illustrations/ttdf.tsx" /* webpackChunkName: "component---src-pages-article-a-love-letter-to-my-favorite-plants-as-told-through-illustrations-ttdf-tsx" */),
  "component---src-pages-article-nostalgic-for-the-present-index-tsx": () => import("./../../../src/pages/article/nostalgic-for-the-present/index.tsx" /* webpackChunkName: "component---src-pages-article-nostalgic-for-the-present-index-tsx" */),
  "component---src-pages-article-page-tsx": () => import("./../../../src/pages/article-page.tsx" /* webpackChunkName: "component---src-pages-article-page-tsx" */),
  "component---src-pages-credits-tsx": () => import("./../../../src/pages/credits.tsx" /* webpackChunkName: "component---src-pages-credits-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-illustration-tsx": () => import("./../../../src/templates/illustration.tsx" /* webpackChunkName: "component---src-templates-illustration-tsx" */),
  "component---src-templates-photo-tsx": () => import("./../../../src/templates/photo.tsx" /* webpackChunkName: "component---src-templates-photo-tsx" */)
}

